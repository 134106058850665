/* .sidenav {
    width: 50px;
} */

.logo {
  width: 50px;
}
.sidenavContainer {
  padding-top: 1rem;
}

.sidenavContainer ul {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
