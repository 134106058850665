@font-face {
    font-family: 'JewelStar';
    src: url('./fonts/JewelStar.eot?wz3moc');
    src: url('./fonts/JewelStar.eot') format('embedded-opentype'), url('./fonts/JewelStar.ttf?wz3moc') format('truetype'), url('./fonts/JewelStar.woff?wz3moc') format('woff'), url('./fonts/JewelStar.svg?wz3moc#JewelStar') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'JewelStar' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-round:before {
    content: "\31";
}

.icon-hexa:before {
    content: "\e907";
}

.icon-bullet:before {
    content: "\e962";
}

.icon-tr:before {
    content: "\e95e";
}

.icon-half-moon:before {
    content: "\e95c";
}

.icon-Single_2:before {
    content: "\32";
}

.icon-Single_3:before {
    content: "\33";
}

.icon-Single_6:before {
    content: "\34";
}

.icon-Single_5:before {
    content: "\35";
}

.icon-Single_4:before {
    content: "\36";
}

.icon-Single_7:before {
    content: "\37";
}

.icon-Single_8:before {
    content: "\38";
}

.icon-Single_9:before {
    content: "\39";
}

.icon-Heart:before {
    content: "\41";
}

.icon-FancyStone:before {
    content: "\42";
}

.icon-Fancy:before {
    content: "\43";
}

.icon-Emerald:before {
    content: "\44";
}

.icon-Cushion:before {
    content: "\45";
}

.icon-CBrilliant:before {
    content: "\46";
}

.icon-HeartArrow:before {
    content: "\47";
}

.icon-EX_EX_EX:before {
    content: "\48";
}

.icon-DIF:before {
    content: "\49";
}

.icon-DealDay:before {
    content: "\4a";
}

.icon-Advance-Search:before {
    content: "\61";
}

.icon-add-stone:before {
    content: "\62";
}

.icon-cart-list:before {
    content: "\63";
}

.icon-MyConfirmation:before {
    content: "\64";
}

.icon-Client-List:before {
    content: "\65";
}

.icon-UserProfile:before {
    content: "\66";
}

.icon-Specific-Search:before {
    content: "\67";
}

.icon-compare-stones:before {
    content: "\68";
}

.icon-confirm-stone:before {
    content: "\69";
}

.icon-confirmation-list:before {
    content: "\6a";
}

.icon-download:before {
    content: "\6b";
}

.icon-Logout:before {
    content: "\6c";
}

.icon-Reset:before {
    content: "\6d";
}

.icon-SavedSearch:before {
    content: "\6e";
}

.icon-Search:before {
    content: "\6f";
}

.icon-Web-Track:before {
    content: "\70";
}

.icon-Forgot-Password:before {
    content: "\71";
}

.icon-excel:before {
    content: "\72";
}

.icon-SqRadiant:before {
    content: "\73";
}

.icon-SqEmerald:before {
    content: "\74";
}

.icon-Round:before {
    content: "\75";
}

.icon-Princess:before {
    content: "\76";
}

.icon-Pear:before {
    content: "\77";
}

.icon-Oval:before {
    content: "\78";
}

.icon-Marquise:before {
    content: "\79";
}

.icon-LgRadiant:before {
    content: "\7a";
}

.icon-other:before {
    content: "\e900";
}

.icon-clac:before {
    content: "\e901";
}

.icon-rap-calc:before {
    content: "\e902";
}

.icon-hexagonal:before {
    content: "\e903";
}

.icon-uniE901:before {
    content: "\e904";
}

.icon-uniE902:before {
    content: "\e905";
}

.icon-uniE903:before {
    content: "\e906";
}

.icon-uniE904:before {
    content: "\e907";
}

.icon-uniE905:before {
    content: "\e908";
}

.icon-plus:before {
    content: "\e909";
}

.icon-cancel:before {
    content: "\e90a";
}

.icon-close-cross:before {
    content: "\e90b";
}

.icon-close-envelope:before {
    content: "\e90c";
}

.icon-skype-logo:before {
    content: "\e90d";
}

.icon-telephone:before {
    content: "\e90e";
}

.icon-man-user:before {
    content: "\e90f";
}

.icon-open-envelope-with-surprise-_a:before {
    content: "\e910";
}

.icon-open-mail:before {
    content: "\e911";
}

.icon-auction-offer:before {
    content: "\e912";
}

.icon-add-auction:before {
    content: "\e913";
}

.icon-auction:before {
    content: "\e914";
}

.icon-Add-to-basket:before {
    content: "\e915";
}

.icon-All-Stone:before {
    content: "\e916";
}

.icon-Appointment-View:before {
    content: "\e917";
}

.icon-Bid-Config-Master:before {
    content: "\e918";
}

.icon-auction-offer-2:before {
    content: "\e919";
}

.icon-auction-view:before {
    content: "\e91a";
}

.icon-Single_10:before {
    content: "\e91b";
}

.icon-Single_11:before {
    content: "\e91c";
}

.icon-Single_12:before {
    content: "\e91d";
}

.icon-Single_13:before {
    content: "\e91e";
}

.icon-Single_14:before {
    content: "\e91f";
}

.icon-Single_15:before {
    content: "\e920";
}

.icon-Single_16:before {
    content: "\e921";
}

.icon-Single_17:before {
    content: "\e922";
}

.icon-Single_18:before {
    content: "\e923";
}

.icon-Twin_1:before {
    content: "\e924";
}

.icon-Twin_2:before {
    content: "\e925";
}

.icon-Twin_3:before {
    content: "\e926";
}

.icon-Twin_4:before {
    content: "\e927";
}

.icon-Twin_5:before {
    content: "\e928";
}

.icon-Twin_6:before {
    content: "\e929";
}

.icon-Twin_7:before {
    content: "\e92a";
}

.icon-Twin_8:before {
    content: "\e92b";
}

.icon-Twin_9:before {
    content: "\e92c";
}

.icon-Twin_10:before {
    content: "\e92d";
}

.icon-Twin_11:before {
    content: "\e92e";
}

.icon-Twin_12:before {
    content: "\e92f";
}

.icon-Twin_13:before {
    content: "\e930";
}

.icon-Twin_14:before {
    content: "\e931";
}

.icon-Twin_15:before {
    content: "\e932";
}

.icon-Twin_16:before {
    content: "\e933";
}

.icon-Twin_17:before {
    content: "\e934";
}

.icon-Twin_18:before {
    content: "\e935";
}

.icon-Change-Profile:before {
    content: "\e936";
}

.icon-Confirm-Permission:before {
    content: "\e937";
}

.icon-Confirm-Report:before {
    content: "\e938";
}

.icon-Confirm-Stone:before {
    content: "\e939";
}

.icon-Confirm-Stone-new:before {
    content: "\e93a";
}

.icon-Create-Albums:before {
    content: "\e93b";
}

.icon-diamond:before {
    content: "\e93c";
}

.icon-Dossier-Round:before {
    content: "\e93d";
}

.icon-Download-all-photo:before {
    content: "\e93e";
}

.icon-Download-Inventory:before {
    content: "\e93f";
}

.icon-Edit-Profile:before {
    content: "\e940";
}

.icon-Email-Configuration:before {
    content: "\e941";
}

.icon-Remove-Stone:before {
    content: "\e942";
}

.icon-list-auction:before {
    content: "\e943";
}

.icon-Book-Appoinment:before {
    content: "\e944";
}

.icon-Sales-Person-Master:before {
    content: "\e945";
}

.icon-Set-result-columns:before {
    content: "\e946";
}

.icon-View-All-Appoinment:before {
    content: "\e947";
}

.icon-revised_price:before {
    content: "\e948";
}

.icon-demand_diam:before {
    content: "\e949";
}

.icon-wishlist:before {
    content: "\e94a";
}

.icon-clarity:before {
    content: "\e94b";
}

.icon-cut_icon:before {
    content: "\e94c";
}

.icon-diamsize_icon:before {
    content: "\e94d";
}

.icon-shape_icon:before {
    content: "\e94e";
}

.icon-symmetry_icon:before {
    content: "\e94f";
}

.icon-baquette:before {
    content: "\e950";
}

.icon-contact-mail:before {
    content: "\e951";
}

.icon-fax:before {
    content: "\e952";
}

.icon-instagram:before {
    content: "\e953";
}

.icon-wechat:before {
    content: "\e954";
}

.icon-whatsapp:before {
    content: "\e955";
}

.icon-location:before {
    content: "\e956";
}

.icon-view-offer:before {
    content: "\e957";
}

.icon-offer-price:before {
    content: "\e958";
}

.icon-stone-dis:before {
    content: "\e959";
}

.icon-jewellery:before {
    content: "\e95a";
}

.icon-Single_19:before {
    content: "\e95b";
}

.icon-Single_20:before {
    content: "\e95c";
}

.icon-Single_21:before {
    content: "\e95d";
}

.icon-Single_22:before {
    content: "\e95e";
}

.icon-uniE95E:before {
    content: "\e95f";
}

.icon-uniE95F:before {
    content: "\e960";
}

.icon-uniE960:before {
    content: "\e961";
}

.icon-Single_23:before {
    content: "\e962";
}

.icon-Single_24:before {
    content: "\e963";
}

.icon-Single_25:before {
    content: "\e964";
}

.icon-Single_26:before {
    content: "\e965";
}
