.mobile-header {
    position: absolute;
    color: #fff;
}

.hero {
  color: #fff;
  background-image: url("../../images//hero_mobile.png");
  background-position: center;
  background-attachment: fixed;
}

.hero .logo {
  height: 120px;
  width: 180px;
}

/* .about {
  background-image: url("../../images/about_mobile.png");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */

.about {
  background-image: 
    /* top, transparent red */ linear-gradient(
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.75)
    ),
    url("../../images/about.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: #ffffff;
  background-position: center;
}


.feed {
  /* background: url("../../images/feed_full.png"); */

  background-image: 
    /* top, transparent red */ linear-gradient(
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.75)
    ),
    url("../../images/feed2.png");
  background-attachment: fixed;
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-dark {
  background: #1b2534;
  color: #ffffff;
}
