@font-face {
    font-family: 'rocksalt';
    src: url('./fonts/rocksalt.eot?eebz4y');
    src: url('./fonts/rocksalt.eot?eebz4y#iefix') format('embedded-opentype'), url('./fonts/rocksalt.ttf?eebz4y') format('truetype'), url('./fonts/rocksalt.woff?eebz4y') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="j-icon-"], [class*=" j-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'rocksalt' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.j-icon-Single_19:before {
    content: "\e90c";
}

.j-icon-Single_20:before {
    content: "\e90d";
}

.j-icon-Single_21:before {
    content: "\e90e";
}

.j-icon-round:before {
    content: "\4f";
}

.j-icon-Single_3:before {
    content: "\51";
}

.j-icon-Single_4:before {
    content: "\52";
}

.j-icon-Single_5:before {
    content: "\53";
}

.j-icon-Single_6:before {
    content: "\54";
}

.j-icon-Single_7:before {
    content: "\55";
}

.j-icon-Single_8:before {
    content: "\56";
}

.j-icon-Single_9:before {
    content: "\57";
}

.j-icon-Single_10:before {
    content: "\58";
}

.j-icon-Single_11:before {
    content: "\59";
}

.j-icon-Single_12:before {
    content: "\5a";
}

.j-icon-Single_13:before {
    content: "\61";
}

.j-icon-Single_14:before {
    content: "\62";
}

.j-icon-Single_15:before {
    content: "\63";
}

.j-icon-Single_18:before {
    content: "\66";
}

.j-icon-back-arrow:before {
    content: "\e900";
}

.j-icon-facebook:before {
    content: "\e901";
}

.j-icon-instagram:before {
    content: "\e902";
}

.j-icon-linkedin:before {
    content: "\e903";
}

.j-icon-pinterest:before {
    content: "\e904";
}

.j-icon-twitter:before {
    content: "\e905";
}

.j-icon-whatsapp:before {
    content: "\e906";
}

.j-icon-skype:before {
    content: "\e907";
}

.j-icon-RapCal:before {
    content: "\e908";
}

.j-icon-contact:before {
    content: "\e909";
}

.j-icon-info:before {
    content: "\e90a";
}

.j-icon-search-plus:before {
    content: "\e90b";
}
