/* .toggle-filter {
    background: cyan;
} */
.toggle-filter button {
  text-transform: none;
}

.toggle-filter button:nth-child(1) {
  padding-left: 0px;
  border-radius: 0px;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
}

.toggle-filter button:last-child {
  border-radius: 0;
  border: 0;
}

.toggle-filter button:hover,
.toggle-filter button.Mui-selected:hover,
.toggle-filter button.Mui-selected {
  background-color: transparent;
}

.toggle-filter button.Mui-selected {
    font-weight: 800;
  }

.selection-button {
  background-color: #f5f5f5;
  padding: 10px;
  cursor: pointer;
  font-size: 35px;
  border-radius: 5px;
}

/* .selection-button-text:hover, */
/* .selection-button:hover, */
.selection-button-text.selected,
.selection-button.selected {
  background-color: #e2eaf8;
  color: #1a4b71; /*#4880FF;*/
}

.selection-button-text {
  background-color: #f5f5f5;
  cursor: pointer;
  border-radius: 5px;
  width: 50px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.selection-button-text.wide {
  width: 100px;
}

.selection-button-text.wide-semi {
  width: 80px;
}

.search-button {
  background-color: #1a4b71; /*#4880FF;*/
  color: white;
  text-decoration: none;
  border-radius: 60px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 50px; /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
}

.search-button:hover {
  max-width: 300px;
}

.search-button .icon {
  padding: 0px 15px;
  display: flex;
  align-items: center;
}

.search-button .text {
  white-space: nowrap;
  padding-right: 15px;
}

.selection-icon-button {
  background-color: #f5f5f5;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  width: 60px;
  align-self: start;
}

.selection-icon-button .icon {
  font-size: 35px;
  font-weight: 500;
}

.selection-icon-button.yellow {
  color: #ffd600;
}

.selection-icon-button.yellow.selected {
  background-color: #fff9c4;
}

.selection-icon-button.orange {
  color: #f57c00;
}

.selection-icon-button.yellow.selected {
  background-color: #fff9c4;
}

.selection-icon-button.orange {
  color: #f57c00;
}

.selection-icon-button.orange.selected {
  background-color: #fff3e0;
}

.selection-icon-button.pink {
  color: #e91e63;
}

.selection-icon-button.pink.selected {
  background-color: #fce4ec;
}

.selection-icon-button.Blue {
  color: #4880ff;
}

.selection-icon-button.Blue.selected {
  background-color: #e2eaf8;
}

.selection-icon-button.Green {
  color: #4caf50;
}

.selection-icon-button.Green.selected {
  background-color: #e8f5e9;
}

.selection-icon-button.Brown {
  color: #795548;
}

.selection-icon-button.Brown.selected {
  background-color: #efebe9;
}

.selection-icon-button.Red {
  color: #e35141;
}

.selection-icon-button.Red.selected {
  background-color: #ffebee;
}

.selection-icon-button.White.selected {
  color: #ffffff;
  background-color: #bdbdbd;
}

.selection-icon-button.Violet {
  color: #3f51b5;
}

.selection-icon-button.Violet.selected {
  background-color: #e8eaf6;
}

.selection-icon-button.Purple {
  color: #9c27b0;
}

.selection-icon-button.Purple.selected {
  background-color: #f3e5f5;
}

.selection-icon-button.Gray {
  color: #9e9e9e;
}

.selection-icon-button.Gray.selected {
  background-color: #fafafa;
}

.selection-icon-button.Gray {
  color: #9e9e9e;
}

.selection-icon-button.Gray.selected {
  /* background-color: #fafafa; */
  background-color: #e0e0e0;
}

.selection-icon-button.Olive {
  color: #afb42b;
}

.selection-icon-button.Olive.selected {
  background-color: #f9fbe7;
}

.selection-icon-button.Black.selected {
  background-color: #e0e0e0;
}
