@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("./jewelstar-font.css");
@import url("./rocksalt-font.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100, "YTLC" 500;

  font-size: 14px;
}

body {
  font-family: "Nunito Sans", sans-serif;
  background-color: #f5f6fa !important;
  color: #202224 !important;
  overflow-x: hidden;
}

.auth-bg {
  /* background-image: url("./images/login-bg.jpeg"); */
  /* background-size: cover; */
  /* width: 100vw;
  height: 100vh;
  background: #0A2D55; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #0A2D55,
    #1A4B71,
    #0A2D55
  );
  background: linear-gradient(
    to bottom,
    #0A2D55,
    #1A4B71,
    #0A2D55
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.table-container {
  padding-top: 64px;
  width: calc(100vw - 240px);
}

.table-container-collapsed {
  padding-top: 64px;
  width: calc(100vw - 60px);
}

.table-container-temporary {
  padding-top: 64px;
  width: 100vw;
}

.MuiListItem-root.Mui-selected {
  /* border-left: 3px solid #4880ff; */
  background-color: transparent !important;
  /* background: #4880FF !important; */
  /* color: #FFFFFF !important; */
}

.MuiListItem-root.Mui-selected:before {
  content: "";
  height: 100%;
  width: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /* background: #000080; */
  background: #1A4B71;
  display: block;
  position: absolute;
  left: 0;
}

.MuiListItem-root .content {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.MuiListItem-root.Mui-selected .content {
  /* background: #000080 !important; */
  background: #1A4B71 !important;
  border-radius: 6px !important;
  color: #fff !important;
}

.MuiListItem-root.Mui-selected .content .MuiListItemIcon-root {
  color: #ffffff !important;
}


.text-primary {
  color: #1A4B71 !important
}