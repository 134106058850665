.stock-image {
    height: 100%;
    width: auto;
}

.stock-video {
    height: 100%;
    width: 100%;
}

.customTableCell {
    background-color: transparent;
    color: black;
}

/* .list-table .MuiTableCell-head.MuiTableCell-root[data-pinned="true"]:before{
    background-color: #1A4B71 !important;
} */