.landing-page-container {
  height: 200vh;
  background: #000;
  /* overflow-x: hidden; */
}

.header {
  position: fixed;
  color: #ffffff;
}

.headline {
  font-weight: 100;
  font-size: 10vw;
}

.inset {
  position: absolute;
  inset: 0;
}

.full {
  height: 100vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient {
  background: linear-gradient(rgba(14, 62, 151, 0.5) 50%, #1B2534);
}

.login-button {
  background-color: #1b2534;
  color: #ffffff;
  padding: 10px 16px;
  font-weight: 600;
  border-radius: 10px;
}

.selection-button {
  background-color: #F5F5F5;
  padding: 10px;
  cursor: pointer;
  font-size: 35px;
  border-radius: 5px;
}

.shape {
  font-size: 50px;
  transition: 0.2s ease;
}

.shape:hover {
  transform: scale(1.2);
}

.feed-image {
  width: 70%;
  align: center;
}

.bg-dark {
  background: #1B2534;
  color: #ffffff;
}


.about-alter {
  background-image:
    /* top, transparent red */
    linear-gradient(rgba(0, 0, 0, 0.60),
      rgba(0, 0, 0, 0.75)),
    url("../../images/about.png");
  /* background-attachment: fixed; */
  background-size: cover;
  color: #ffffff;
}